import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2'
import axios from 'axios'
import gif from './carregando-criacao-site.gif'
import Modal from './Modal'
import Modal2 from './Modal2'
import Modal3 from './Modal3'
import Modal4 from './Modal4'
import Modal5 from './Modal5'
import './Modal.css';
import { saveAs } from 'file-saver';

const Result = () => {
    return (
        <p className="success-message">Seu site foi criado com sucesso. Voce vai receber o link do mesmo e os dados para acesso no email em 3 minutos.</p>
    )
}

function removerCaracteresEspeciais(str) {
    // Transforma todas as letras maiúsculas em minúsculas
    str = str.toLowerCase()
    // Expressão regular para remover caracteres especiais, números, espaços e 'ç'
    let resultado = str.replace(/[^a-zà-ú]/g, '')
    // Limita a string resultante a 30 caracteres
    return resultado.substring(0, 30)
}

function limitarTamanho(str) {

    return str.substring(0, 50)
}

function CreateSiteForm({ props }) {



    return (
        <>
           
                <button
                    className="rn-button-style--2 btn-solid"
                    style={{ width: '100%' }}
                    onClick={() => {
                        window.open("https://painel.whats-bot-ia.com.br/novo_formulario", "_blank");
                      }}                   
                    name="submit" id="mc-embedded-subscribe">
                   Faça um teste gratís
                </button>
         
        </>
    )
}
export default CreateSiteForm;
