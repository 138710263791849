import React, { Component } from "react";
import CreateSiteForm from "./CreateSiteForm";

import video from "./video.mp4";

class CreateSite extends Component {
  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-5 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">Faça um teste grátis</h2>
                <p className="description">
                  Crie instruções para a inteligência artificial para ser o que você quiser ! Enviaremos uma mensagem com o bot treinado de acordo com suas instruções.
                </p>
              </div>
              <div className="form-wrapper">
                <CreateSiteForm />
              </div>
            </div>
            <div className="col-lg-7 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <video
                  src={video}
                  autoPlay
                  loop
                  muted
                  style={{ borderRadius: '10px', marginTop: '5px', width: '100%', height: 'auto' }}
                />              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CreateSite;
