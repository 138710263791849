import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { slickDot } from "../page-demo/script";
import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import CounterPayd from "../elements/counters/CounterPayd"
import BlogContent from "../elements/blog/BlogContent";
import FooterTwo from "../component/footer/FooterTwo";
import CreateSite from "../elements/contact/CreateSite";
import Particles from "react-tsparticles";
import Helmet from "../component/common/Helmet";
import TabThree from "../elements/tab/TabThree";
import Conversation from "./Conversation";
import logoLight from "../assets/images/logo/logo-light.png";
import logoAllDark from "../assets/images/logo/logo-all-dark.png";
import SobreImg from "../assets/images/bg/111.png";
import './CreativeLanding.css'
const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "Creative ",
    description:
      "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
];

const particlesInit = async (engine) => {
  await loadFull(engine);
};

const list = [
  {
    image: "image-1",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-2",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-3",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-4",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-3",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-4",
    category: "Development",
    title: "Getting tickets to the big show",
  },
];


class CreativeLanding extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }


  componentDidMount() {
    // document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    // document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidMount() {
    // this.handleClickOutsideDiv2 = this.handleClickOutsideDiv2.bind(this);
    // document.addEventListener('click', this.handleClickOutsideDiv2);
  }

  componentWillUnmount() {
    // document.removeEventListener('click', this.handleClickOutsideDiv2);
  }

  handleClickOutsideDiv2(event) {
    // if (event.target.closest("#div1")) {
    //   if (!event.target.closest("#div2")) {
    //     console.log("Clique na div1 e fora da div2!");
    //     this.CLoseMenuTrigger()
    //     // Aqui você pode executar qualquer ação desejada
    //   }
    // }
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");

  }
  CLoseMenuTrigger() {

    document.querySelector(".header-wrapper").classList.remove("menu-open");

  }
  stickyHeader() { }
  render() {
    const PostList = BlogContent.slice(0, 5);

    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    return (
      <Fragment>
        
        <Helmet pageTitle="Creative Landing" />
        <div id="div1">

          {/* Start Header Area  */}
          <div id="div2">


            <header className="header-area formobile-menu header--fixed default-color">
              
              <div className="header-wrapper" id="header-wrapper">
                <div className="header-left">
                  <div className="logo">
                    <a href="/">
                      <img className="logo-1" src={logoLight} alt="imobil site para imobiliária e imobil site para corretores" width="200px" />
                      <img className="logo-2" src={logoAllDark} alt="imobil site para imobiliária e imobil site para corretores" />
                    </a>
                  </div>
                </div>
                <div className="header-right">
                  <nav className="mainmenunav d-lg-block">
                    <Scrollspy
                      className="mainmenu"
                      items={[
                        "home",
                        "criarsite",
                        "Sobre",
                        "precos",
                      ]}
                      currentClassName="is-current"
                      offset={-200}
                    >
                      <li onClick={this.CLoseMenuTrigger}>
                        <a href="#home">Home</a>
                      </li>
                      <li onClick={this.CLoseMenuTrigger}>
                        <a href="#criarsite">Teste grátis</a>
                      </li>
                      <li onClick={this.CLoseMenuTrigger}>
                        <a href="#Sobre">Sobre nós</a>
                      </li>
                      <li onClick={this.CLoseMenuTrigger}>
                        <a href="#precos">Preço</a>
                      </li>
                      {/* <li onClick={this.CLoseMenuTrigger}>
                        <a href="#precos">Preço</a>
                      </li> */}
                      <li onClick={this.CLoseMenuTrigger}>
                        <a href="https://painel.whats-bot-ia.com.br/login">Login</a>
                      </li>
                      {/* <li>
                    <a href="#contact">Contato</a>
                  </li> */}
                    </Scrollspy>
                  </nav>
                  <div className="header-btn">
                    <a
                      className="rn-btn"
                      href="https://painel.whats-bot-ia.com.br/novo_cadastro"
                    >
                      <span>Cadastre-se</span>
                    </a>
                  </div>
                  {/* Start Humberger Menu  */}
                  <div className="humberger-menu d-block d-lg-none pl--20">
                    <span
                      onClick={this.menuTrigger}
                      className="menutrigger text-white"
                    >
                      <FiMenu />
                    </span>
                  </div>
                  {/* End Humberger Menu  */}
                  <div className="close-menu d-block d-lg-none">
                    <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                      <FiX />
                    </span>
                  </div>
                </div>
              </div>
            </header>
          </div>
          {/* End Header Area  */}
          {/* <Conversation></Conversation> */}

          {/* Start Slider Area   */}
          <div className="slider-activation slider-creative-agency" id="home">
            
          <div className="c elemento-pai" >
          <Particles
          id="tsparticles"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '500px',
          }}
          options={{
            fullScreen: {
              enable: false, // Desativa o modo de tela cheia
            },
            background: {
              color: "#000000",
            },
            particles: {
              color: {
                value: "#ff0000",
              },
              links: {
                color: "#ff0000",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              move: {
                enable: true,
                speed: 1,
              },
              number: {
                value: 250,
              },
              size: {
                value: 3,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
                onclick: {
                  enable: true,
                  mode: "push",
                },
              },
              modes: {
                repulse: {
                  distance: 100,
                  duration: 0.4,
                },
                push: {
                  quantity: 4, // Ajuste aqui, pois 'particles_nb' foi renomeado para 'quantity'
                },
              },
            },
          }}
        />
              {SlideList.map((value, index) => (
                <div
                  className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
                  key={index}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title theme-gradient">
                              Whats bot IA
                            </h1>

                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p style={{ fontSize: '25px' }} className="description"><strong>Treine a sua <a href="javascript:void(0)">própria</a> inteligencia artificial para responder mensagens no whatsapp para <a href="javascript:void(0)">você</a></strong></p>
                          ) : (
                            ""
                          )}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-primary-color"
                                href="https://painel.whats-bot-ia.com.br/novo_formulario"
                                target="_blank"
                              >
                                Faça um teste grátis
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* End Slider Area   */}
          <div className="rn-contact-us ptb--120 bg_color--1" id="criarsite">
            <CreateSite />
          </div>

          {/* Start Sobre Area */}
          <div className="Sobre-area ptb--120 bg_color--5" id="Sobre">
            <div className="Sobre-wrapper">
              <div className="container">
                <div className="row row--35 align-items-center">
                  <div className="col-lg-5" style={{ marginBottom: '20px' }}>
                    <div className="thumbnail">
                      <img className="w-100" src={SobreImg} alt="imobil site para imobiliária e imobil site para corretores" style={{ borderRadius: '10px' }} />
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="Sobre-inner inner">
                      <div className="section-title">
                        <h3 className="title">Bot para Whatsapp com </h3>
                        <h3 className="title">Inteligência Artificial</h3>
                        <br></br>
                        <p className="description">
                          Configure uma Inteligência Artificial <a href="javascript:void(0)">própria</a> para responder mensagens no whatsapp.  {" "}

                        </p>
                      </div>
                      <div className="row mt--30">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12" style={{ marginBottom: '20px' }}>
                          <div className="Sobre-us-list">
                            <h3 className="title">Treine sua inteligencia artificial</h3>
                            <p>
                              Crie instruções ilimitadas para sua inteligencia artificial. Quanto mais instruções forem fornecidas,
                              mais ela estará preparada.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <div className="Sobre-us-list">
                            <h3 className="title">Ative ou inative a qualquer momento</h3>
                            <p>
                              Com apenas 1 clique você poderá ativar ou inativar a sua inteligencia artificial. Suas instruções estarão salvas quando for ativada novamente.
                            </p>
                          </div>
                        </div>
                        <br></br>


                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>
          </div>
          {/* End Sobre Area */}

          
          <div id="precos">
            <div className="rn-counterup-area pt--140 p pb--110 bg_color--1">
              <div className="container">
              <h1 className="fontWeight900" style={{textAlign:'center', marginBottom: '9vh'}}>Preços</h1>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title text-center">
                    <h3 className="fontWeight900">Conheça os planos para ter o bot inteligente simulando uma pessoa real</h3>
                    </div>
                  </div>
                </div>
                <CounterPayd />
              </div>
            </div>
          </div>



          <FooterTwo />

          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default CreativeLanding;
