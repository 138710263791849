// React Required
import React, { Component } from "react";
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

import CreativeLanding from "./home/CreativeLanding";

import error404 from "./elements/error404";
// Blocks Layout

import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
// const logoUrl = 'https://firebasestorage.googleapis.com/v0/b/imobil-415717.appspot.com/o/template%2Flogo-symbol-dark.png?alt=media&token=a6380426-77a8-43b8-97da-b8adf15e3598';
// // Define o URL da imagem como o ícone no cabeçalho do documento
// const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
// link.type = 'image/x-icon';
// link.rel = 'shortcut icon';
// link.href = logoUrl;
// document.getElementsByTagName('head')[0].appendChild(link);
class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={CreativeLanding} />
            <Route component={error404} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
serviceWorker.register();
