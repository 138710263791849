import React from "react";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import Particles from "react-tsparticles";

import logo from "../../assets/images/logo/logo-light.png";

const SocialShare = [

  { Social: <FaInstagram />, link: "https://www.instagram.com/whats_bot_ia/" },
];

const FooterTwo = () => {
  return (
    <div
      className="footer-style-2 ptb--30 "
      data-black-overlay="10"
    >
       <Particles
          id="tsparticles"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '500px',
          }}
          options={{
            fullScreen: {
              enable: false, // Desativa o modo de tela cheia
            },
            background: {
              color: "#000000",
            },
            particles: {
              color: {
                value: "#ff0000",
              },
              links: {
                color: "#ff0000",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              move: {
                enable: true,
                speed: 1,
              },
              number: {
                value: 50,
              },
              size: {
                value: 3,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
                onclick: {
                  enable: true,
                  mode: "push",
                },
              },
              modes: {
                repulse: {
                  distance: 100,
                  duration: 0.4,
                },
                push: {
                  quantity: 4, // Ajuste aqui, pois 'particles_nb' foi renomeado para 'quantity'
                },
              },
            },
          }}
        />
      <div className="wrapper plr--50 plr_sm--20">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner">
              <div className="logo text-center text-sm-left mb_sm--20">
                <a href="/home-one">
                  <img src={logo} alt="Logo images" style={{width: '180px'}}/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner text-center">
              <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a href={`${val.link}`}>{val.Social}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
              <div className="text">
                <p>Copyright © 2024 WHATS BOT IA - BOTS COM INTELIGENCIA ARTIFICIAL</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FooterTwo;
